

import React, {useMemo} from 'react';
import AlgoliaProvider from '@ui/provider/algolia';
import {Box} from 'native-base';
import CompetitionCard from '../components/CompetitionCard';
import {CompetitionSearchFeaturedChallenges} from './CompetitionSearchFeaturedChallenges';
import {CompetitionUiPreviewData} from '@shared/models/competition';
import InfiniteHits from '@ui/components/InfiniteHits';
import SearchBox from '@ui/components/SearchBox';
import {getIndexes} from '@ui/provider/algolia/searchClient';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

const ONGOING_STATUS = 'status:REGISTRATION OR status:ONGOING OR status:UPCOMING OR status:FINISHED OR status:REGISTRATION_ENDED';
const SEARCH_STATUS = `${ONGOING_STATUS} OR status:CANCELED`;

interface Props {
    Header?: JSX.Element
    Footer?: JSX.Element
    EmptyView?: JSX.Element;
    extraFilter?: string;
    renderItem?: (item: CompetitionUiPreviewData) => JSX.Element;
    showHeaderOnSearch?: boolean;
    showResultsOnEmptySearch?: boolean;
    showSearch?: boolean;
    searchPlaceholder?: string;
}

export const CompetitionSearchList = (props: Props) => {
    const {Header = <Box h='4' />, Footer, renderItem, extraFilter, showHeaderOnSearch = true, showResultsOnEmptySearch = true, showSearch = true, EmptyView, searchPlaceholder} = props;
    const [search, setSearch] = React.useState('');
    const {user} = useUser();
    const competitions = Object.values(user?.competitions ?? {});

    const hasSearch = search.length > 0;

    const filter = useMemo(() => [hasSearch ? SEARCH_STATUS : ONGOING_STATUS, extraFilter].filter(Boolean).join(' AND '), [extraFilter, hasSearch]);

    return (
        <AlgoliaProvider
            indexName={getIndexes()['competitions']}
            configure={{hitsPerPage: showResultsOnEmptySearch || hasSearch ? 10 : 0}}
            filters={filter}
        >
            {showSearch && <SearchBox setSearch={setSearch} placeholder={searchPlaceholder}/>}

            <InfiniteHits
                ItemSeparatorComponent={() => (
                    <Box h='4'/>
                )}
                ListHeaderComponent={showHeaderOnSearch || !hasSearch ? Header : <Box h='4' />}
                ListFooterComponent={showHeaderOnSearch || !hasSearch ? Footer : <Box h='4' />}
                ListEmptyComponent={EmptyView}
                renderItem={({item}: {item: CompetitionUiPreviewData}) =>
                    renderItem ? renderItem(item) :
                        <Box px='4'>
                            <CompetitionCard
                                competition={item}
                                userJoined={competitions.some(c => c.id === item.id)}
                            />
                        </Box>
                }
                isBottomsheet={false}
            />
        </AlgoliaProvider>
    );
};

const CHALLENGE_TYPE = 'challengeType:COMPETITION';

const CompetitionSearchListWithFeaturedChallenges = (props: Props) => {
    const {t} = useTranslation();

    return (
        <CompetitionSearchList
            Header={<CompetitionSearchFeaturedChallenges />}
            extraFilter={CHALLENGE_TYPE}
            searchPlaceholder={t('search.events')}
            {...props}
        />
    );
};


export default CompetitionSearchListWithFeaturedChallenges;
