import {Column, Text} from 'native-base';
import {FeaturedChallenges} from '@ui/features/clubhouse/FeaturedChallenges';
import React from 'react';
import {useDashboardAssets} from '@ui/features/clubhouse/hooks/dashboard-hooks';

export const CompetitionSearchFeaturedChallenges = () => {
    const [config] = useDashboardAssets();
    const featuredChallenges = config?.searchChallenges?.challengesWithLabel ?? [];
    const title = config?.searchChallenges?.title?.en;
    const description = config?.searchChallenges?.description?.en;

    return (
        <Column space='4' w='full' p='4'>
            {title && <Text variant='h5'>{title}</Text>}

            {description && <Text variant='body' color='blacks.700'>{description}</Text>}

            <FeaturedChallenges items={featuredChallenges} />
        </Column>
    );
};
